<script lang="ts" setup>
defineProps<{
	location?: string;
	isDisabledPrev: boolean;
	isDisabledNext: boolean;
	viewAllLabel?: string;
	locationTid?: string;
}>();

defineEmits<{
	(event: "clickPrev"): void;
	(event: "clickNext"): void;
	(event: "clickViewAll"): void;
}>();

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};
</script>
<template>
	<div class="navigation-holder">
		<AButtonGroup class="navigation">
			<AButton
				:id="`prev-btn-${location}`"
				:data-tid="locationTid ? `slider-${getTitle(locationTid)}-prev` : null"
				aria-label="Previous"
				variant="secondary"
				:modifiers="[isDisabledPrev ? 'disabled' : '']"
				@click="$emit('clickPrev')"
			>
				<NuxtIcon filled name="16/arrow-prev" />
			</AButton>
			<AButton
				:id="`next-btn-${location}`"
				aria-label="Next"
				variant="secondary"
				:data-tid="locationTid ? `slider-${getTitle(locationTid)}-next` : null"
				:modifiers="[isDisabledNext ? 'disabled' : '']"
				@click="$emit('clickNext')"
			>
				<NuxtIcon filled name="16/arrow-next" />
			</AButton>
		</AButtonGroup>
		<AButton
			v-if="viewAllLabel"
			variant="secondary"
			:data-tid="locationTid ? `slider-${getTitle(locationTid)}-all` : null"
			@click="$emit('clickViewAll')"
		>
			<AText :modifiers="['uppercase']">{{ viewAllLabel }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.navigation-holder {
	display: flex;
	gap: gutter(2);
	height: 36px;
}

.navigation {
	background: transparent;
	position: relative;
	box-shadow: var(--a-button-secondary-box-shadow);

	&::before {
		content: "";
		display: block;
		position: absolute;
		width: 1px;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		background: var(--cannes);
		opacity: 0.1;
	}

	button {
		&.secondary {
			background: var(--carpi);
		}

		&.disabled .nuxt-icon {
			opacity: 0.5;
		}
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}
}
</style>
